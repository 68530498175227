.loader-swiper {
  width: 250px !important;
  height: 75px !important;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loader-swiper .swiper-slide {
  transition: all 0.5s;
  background-position: center;
  background-size: cover;
  width: 60px;
  height: 60px;
}

.loader-swiper .swiper-slide .image {
  opacity: 0.5;
}

.loader-swiper .swiper-slide-active {
  width: 75px;
  height: 75px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.loader-swiper .swiper-slide-active .image {
  opacity: 1;
}

.submited-swiper {
  width: calc(100vw - 40px) !important;
  height: 100px !important;
}

.submited-swipe .swiper-slide {
  width: 100px !important;
  height: 100px !important;
}
