@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-families: 'Work Sans Variable', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-families);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-gradient-button {
  background: linear-gradient(
    81.87deg,
    #2c5085 -17.77%,
    rgba(106, 169, 255, 0) 260.11%
  );
}

.swiper-slide-shadow-right,
.swiper-slide-shadow-left {
  background-image: none;
}
