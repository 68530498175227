.image-container {
  position: relative;
  width: 100%;
}

.background-image {
  width: 100%;
  height: auto;
}

.overlay-elements {
  pointer-events: none;
}
